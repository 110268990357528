import * as React from "react"
import withLayout from "../hoc/withLayout";
import { CityPageHeader } from "../components/CityPageHeader";
import GomelImg from "../images/gorod-gomel-lg.jpg";
import GomelImgWebp from "../images/gorod-gomel-lg.jpg.webp";
import GomelMapImg from "../images/gorod-gomel-map.png";
import GomelMapImgWebp from "../images/gorod-gomel-map.png.webp";

import IndexPrice from "../components/index/price/Price";
import { CityPageDelivery } from "../components/CityPageDelivery";
import HelpPhone from "../components/HelpPhone";
import Ecology from "../components/Ecology";
import Map from "../components/Map";
import { CityPageAboutUs } from "../components/CityPageAboutUs";



const Gomel = () => {
  return (
    <>
      <CityPageHeader 
        header={<h1>Чистка сажевого фильтра в Гомеле <small>и Гомельской области</small></h1>} 
        jpg={GomelImg} 
        webp={GomelImgWebp} 
        alt="Чистка сажевого фильтра в Гомеле и Гомельской области"
      />

      <IndexPrice/>
      
      <CityPageDelivery 
        from="Гомеля и Гомельской области" 
        jpg={GomelMapImg}
        webp={GomelMapImgWebp}
        alt="Доставка сажевых фильтров в Гомель и Гомельскую область"
      />

      <HelpPhone/>
      <CityPageAboutUs/>
      <Ecology/>
      <Map/>
    </>
  )
}

export default withLayout(
  Gomel, 
  "Чистка сажевых фильтров в Гомеле",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
