import * as React from "react";
import Container from 'react-bootstrap/Container';
import "./cityPageAboutUs.scss";

export const CityPageAboutUs = () => {
  return (
    <Container className="city-page-about-us">
      <h2>Почему выбирают нас?</h2>
      
      <div className="wrapper">
        <div className="city-page__benefit">
          <h3>По Беларуси БЕСПЛАТНО</h3>
          <p className="subheader">Заберем и доставим сажевый фильтр</p>
          <p className="description">
            Возможность бесплатной доставки в ваш населенный пункт, в необходимое для вас время, уточняйте у менеджера
          </p>
        </div>

        <div className="city-page__benefit">
          <h3>Удаление 99 % загрязнений</h3>
          <p className="subheader">Возникающих из сажи, серы, масла из двигателя и охлаждающей жидкости,без повреждения картриджа фильтра</p>
        </div>

        <div className="city-page__benefit">
          <h3>Очистка DPF / FAP / SCR / KAT на современном Европейском оборудовании</h3>
          <p className="subheader">
            С применением экологически чистых моющих средств, безопасных для фильтрующего элемента.
            Предоставляются результаты чистки с показателями ДО и ПОСЛЕ промывки
          </p>
        </div>
      </div>
    </Container>
  )
}