import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import BannerImg from "../images/gorod-banner.jpg";
import BannerImgWebp from "../images/gorod-banner.jpg.webp";

import "./cityPageHeader.scss";


export const CityPageHeader = ({ header, jpg, webp, alt }) => {
  return (
    <div className="city-page-header">
      <picture className='city-page-header__bg'>
        <source srcSet={webp} type="image/webp" />
        <img src={jpg} alt={alt} />
      </picture>

      <Container>
        {header}
      
        <picture className='city-page-header__banner'>
          <source srcSet={BannerImgWebp} type="image/webp" />
          <img src={BannerImg} alt="процесс чистки сажевого фильтра" />
        </picture>
      </Container>
    </div>
  )
}


CityPageHeader.propTypes = {
  header: PropTypes.node.isRequired, 
  jpg: PropTypes.string.isRequired,
  webp: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}