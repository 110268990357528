import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./cityPageDelivery.scss";


export const CityPageDelivery = ({from, jpg, webp, alt }) => {
  return (
    <Container className='city-page-delivery'>
      <h2>Условия доставки в Минск</h2>
      <Row>
        <Col xs={12} lg={{span:6, order: 'last'}} xl={{span:4, order: 'last'}}>
          <picture>
            <source srcSet={webp} type="image/webp" />
            <img src={jpg} alt={alt} />
          </picture>
        </Col>
        
        <Col xs={12} lg={{ span: 6, order: 'first'}} xl={{span:8, order: 'first'}}>
          <ul>
            <li>Забираем СНЯТЫЙ сажевый фильтр из {from}</li>
            <li>После процедуры отправляем обратно очищенный фильтр в кратчайшие сроки и без дополнительных оплат</li>
            <li>Возможность бесплатной доставки в ваш населенный пункт, в необходимое для вас время, уточняйте у менеджера</li>
            <li>Высылаем любыми транспортными компаниями во все регионы Беларуси</li>
          </ul>
        </Col>

      </Row>
    </Container>
  )
}


CityPageDelivery.propTypes = {
  from: PropTypes.string.isRequired,
  jpg: PropTypes.string.isRequired,
  webp: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}